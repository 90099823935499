a {
    text-decoration: none;
}

.headerPart {
    position: fixed;
    height: 56px;
    padding: 0 32px;
    background: #ffffff;
    width: 100vw;
    z-index: 3;
}

.headerPart .menuIcon {
    width: 24px;
    height: 24 px;
    margin-right: 16px;
}

.headerPart .logostore {
    display: block;
    min-width: 90px;
    height: 40px;
    justify-items: center;
    cursor: pointer;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: var(--storeLogo);
}

.headerPart .input-search {
    height: 24px;
    width: 160px;
    margin-right: 20px;
    padding: 8px;
    font-family: proximanova_bold, Arial;
    font-size: 14px;
}

.headerPart .header-link {
    margin: 0 10px;
    font-size: 13px;
    color: rgba(0, 0, 0, 1);
    font-family: proximanova_bold, Arial;
    text-decoration: none;
}

.headerPart .header-link:hover {
    text-decoration: underline;
}


.menu-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    z-index: -1;
    background: rgba(0, 0, 0, 0);
    transition-delay: 0.5s;
}

.menu-bg-open {
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    transition-delay: 0s;
}

.menu-bg .menu {
    height: 100%;
    width: 0px;
    background: rgba(255, 255, 255, 1);
    z-index: 1;
    box-shadow: 0 8px 8px 0 rgba(103, 151, 255, .11), 0 12px 18px 0 rgba(103, 151, 255, .11);
    overflow: hidden;
    transition: width 0.5s ease;
}

.menu-bg .menu-open {
    width: 290px;
}

.menu-bg .menu .menu-close-icon {
    width: 260px;
    height: 24px;
    margin: 24px 0;
    display: flex;
    justify-content: flex-end;
}

.menu-bg .menu .input-search {
    font-family: proximanova_bold, Arial;
    margin: 0 15px 20px;
    width: 260px;
}

.menu-bg .menu .nav-link {
    width: 260px;
    font-family: proximanova_bold, Arial;
    color: rgba(34, 36, 85, 1);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    margin: 0 15px;
}


.bgColor {
    background: var(--backgroundColor);
}

.bgImage {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 120px;
}

.bgImage-left {
    background-position: left bottom;
}

.bgImage-right {
    background-position: right bottom;

}

.resultFailPart, .pricePart, .informationPart, .orderPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.resultSuccessPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.introductionPart {
    width: 100%;
    height: auto;
}

.introductionPart .image {
    margin-top: 56px;
    height: auto;
    width: 100%;
}

.introductionPart .title {
    text-align: center;
    letter-spacing: -0.50px;
    color: var(--titleColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 32px;
    font-family: proximanova_bold, Arial;
}

.introductionPart .description {
    text-align: center;
    color: rgba(34, 36, 85, 1);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
}

.introductionPart .storebg {
    position: absolute;
    height: 45%;
    bottom: 16px;
}

.introductionPart .storebgimg {
    position: relative;
    height: 100%;
}

.introductionPart .storebgimg2 {
    position: absolute;
    height: 100%;
    top: 0;
    left: 100%;
}

.introductionPart .logostorebigger {
    position: absolute;
    top: 5%;
    left: 51%;
    height: 11%;
    min-width: 20%;
    transform: translateX(-50%);
    background-image: var(--storeLogo);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.filterPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding-bottom: 60px;
}

.filterPart .title {
    text-align: center;
    color: var(--titleColor);
    font-size: 70px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    width: 100%;
    /* margin-top: 30px; */

}

.filterPart .titlesecondary {
    text-align: center;
    color: var(--underTextColor);
    font-size: 60px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
    width: 100%;

}

.filterPart .description {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 22px;
    font-weight: 400;
    font-family: proximanova_regular, Arial;
    width: 100%;
    padding: 0 5%;
    margin-top: 5%;
}


.filterPart .autocomplete,
.filterPart .selectinput {
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    border-color: var(--borderColor);
    border-style: solid;
    border-width: 3px;
    border-radius: 4px;
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
}

.filterPart .selectinput-sm {
    width: 150px;
}

.filterPart .input-text,
.filterPart .selectState {
    color: rgba(16, 38, 106, 1);
    -webkit-text-stroke: 1px rgba(112, 112, 112, 1);
    font-weight: 400;
    font-family: proximanova_regular, Arial;
    width: 100%;
    border: none;
    outline: 0;
    z-index: 1;
    background: none;
}

.filterPart .selectState:disabled {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
}

.filterPart .input-text:focus,
.filterPart .selectState:focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.filterPart .selectinput .dropdown_icon {
    position: absolute;
    right: 0;
    z-index: 0;
    width: 20px;
    margin: 0 5px;
    transform: rotateZ(0deg);
}

.filterPart .selectinput .dropdown_icon_active {
    transform: rotateZ(180deg);
}

.filterPart .dropdown-list {
    position: absolute;
    width: 100%;
    top: 40px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid rgb(223, 223, 223);
    z-index: 10;
}

.filterPart .dropdown-list .dropdown-item {
    background-color: rgba(252, 252, 252, 1);
    font-family: proximanova_regular, Arial;
}

.filterPart .dropdown-list .dropdown-item:hover {
    background-color: rgb(189, 189, 189);
}

.filterPart .submitbutton {
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.filterPart .submitbutton .btn {
    background-color: var(--buttonConfirmColor);
    border-style: none;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: proximanova_bold, Arial;
    color: rgba(252, 252, 252, 1);
    font-size: 16px;
    font-weight: 700;

}

.filterPart .submitbutton .btn:disabled {
    background-color: rgb(119, 119, 119) !important;
}


.informationPart .line1 {
    color: var(--titleColor);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
}

.informationPart .line2 {
    color: var(--titleColor);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    ;
}

.informationPart .img1 {
    width: 270px;
    display: flex;
    align-items: flex-end;
}

.informationPart .img2 {
    display: flex;
    width: 370px;
    align-items: flex-end;
}


.footerPart {
    width: 100%;
    padding: 30px;
    background-color: var(--backgroundColorFooter);
}

.footerPart .moreinformation {
    width: 400px;
    margin: 10px 0;
}

.footerPart.subscribe {
    margin: 10px 0;
    width: 400px;
}

.footerPart .moreinformation .info-col {
    margin: 0 15px;
    width: 200px;
}

.footerPart .moreinformation .coltitle {
    color: rgba(34, 36, 85, 1);
    font-size: 20px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.footerPart .moreinformation .colitem {
    color: rgba(34, 36, 85, 1);
    font-size: 17px;
    font-weight: 400;
    font-family: proximanova_regular, Arial;
    min-width: 200px;
}


.footerPart .emailsubscribeinput .subscribetitle {
    color: rgba(34, 36, 85, 1);
    font-size: 18px;
    font-family: proximanova_regular, Arial;
    margin-bottom: 16px;
}

.footerPart .emailsubscribeinput .emailinput {
    color: rgba(34, 36, 85, 1);
    font-weight: 400;
    border-color: rgba(34, 36, 85, 1);
    border-style: solid;
    border-width: 1px;
    width: 70%;
    padding: 8px 10px;
    font-size: 16px;
    font-family: proximanova_regular, Arial;

}

.footerPart .emailsubscribeinput .emailinput:focus {
    outline: 0;
    border-radius: 0;
}


.footerPart .emailsubscribeinput .subscribebtn {
    background-color: rgba(34, 36, 85, 1);
    color: rgba(255, 255, 255, 1);
    border-style: none;
    border-width: 1px;
    padding: 5px 10px;
    font-size: 16px;
    font-family: proximanova_regular, Arial;
    width: 30%;
}

.footerPart .scocialgroup {
    margin: 32px 24px;
    width: 200px;
}

.footerPart .scocialgroup .icon {
    width: 24px;
}

.footerPart .copyrighttext {
    color: var(--titleColor);
    font-size: 18px;
    font-family: somaticrounded, Arial;
    text-align: center;

}

.pricePart .title {
    color: var(--titleColor);
    font-size: 40px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    text-align: center;
    margin-top: 5%;
}

.pricePart .filterBox {
    background-color: var(--backgroundColor);
    border-color: rgba(112, 112, 112, 1);
    border-style: none;
    border-width: 1px;
    border-radius: 30px;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    width: 1000px;
    margin-top: 16px;
    padding: 16px 24px;
}

.pricePart .filterBox .filter {
    text-align: center;
}

.filter .name {
    color: var(--titleColor);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
}

.filter .value {
    color: var(--underTextColor);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    margin-top: 10px;
}

.pricePart .price {
    color: var(--titleColor);
    font-size: 56px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
}

.pricePart .description {
    color: var(--titleColor);
    font-size: 32px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
}

.btnGroup {
    width: 400px;
    margin-top: 60px;
}

.btnGroup .btn {
    color: rgba(255, 255, 255, 1);
    font-size: 16 px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    padding: 4px 32px;
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 40px;
}

.btnGroup .btn:disabled {
    background-color: rgb(119, 119, 119) !important;
}

.btn-green {
    background-color: var(--buttonConfirmColor);
}

.btn-gray {
    background-color: rgb(119, 119, 119);
}

.btn-blue {
    background-color: var(--buttonNewColor);
}

.getQrPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 0;
}

.getQrPart .primarylogo {
    height: 250px;
    min-width: 350px;
    background-image: var(--storeLogo);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.resultFailPart .logostorebigger,
.getQrPart .logostorebigger {
    height: 90px;
    min-width: 240px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: var(--storeLogo);
}

.getQrPart .input_mail {
    background-color: rgba(255, 255, 255, 1);
    border-color: var(--borderColor);
    border-style: solid;
    border-width: 4px;
    border-radius: 6px;
    font-family: proximanova_regular, Arial;
    font-size: 16px;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    width: 350px;
    padding: 8px;
    margin: 20px 0 0px;
}


.resultSuccessPart .title,
.getQrPart .title {
    color: var(--titleColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 32px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    text-decoration: none;

}

.getQrPart .description {
    text-align: center;
    color: rgba(34, 36, 85, 1);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
    width: 80%;
    margin: 10px;
}

.getQrPart .description {
    font-size: 16px;
    margin: 0px;
}

.resultFailPart,
.resultSuccessPart {
    padding-top: 5%;
}

.resultSuccessPart .primarylogo {
    height: 90px;
    min-width: 240px;
    background-image: var(--primaryLogo);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.resultSuccessPart .title {
    color: var(--underTextColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 40px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    text-decoration: none;

}

.resultSuccessPart .qrcode-img {
    height: 260px;
}

.resultFailPart .message,
.resultSuccessPart .message {
    color: var(--titleColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 20px;
    font-weight: 700;
    font-family: proximanova_bold, Arial;
    padding: 0 10px;
    margin: 0 0;
    text-align: center;
}

.resultFailPart .message {
    font-size: 32px;
}

.resultFailPart .message-highlight,
.resultSuccessPart .message-highlight {
    margin: 0 8px;
    color: var(--underTextColor);
}

.resultFailPart .description {
    text-align: center;
    color: var(--underTextColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
    width: 80%;
    margin: 20px;
}

.resultSuccessPart .description {
    color: var(--underTextColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 16px;
    font-weight: 400;
    font-family: proximanova_regular, Arial;
    text-align: center;
    margin: 8px 0;
}

.resultSuccessPart .logostorebigger {
    height: 90px;
    min-width: 240px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: var(--storeLogo);
}

.resultSuccessPart .btnGroup {
    margin-top: 10px;
}

.resultSuccessPart .store-table .scheduler-row {
    display: flex;
    min-width:220px;
}

.resultSuccessPart .store-table .scheduler-row>div:first-child {
    width: 100px;
}

.orderPart {
    padding-top: 80px;
    margin: auto;
    min-height: 100vh;
    height: auto;
    padding-bottom: 30px;
}

.resultSuccessPart .store-table,
.orders-table {
    margin: 0 auto;
    overflow: auto;
    max-width: 100vw;
    font-family: proximanova_regular, Arial;
}

.orders-table table {
    border-radius: 24px;
    max-width: 1000px;
    color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    box-shadow: 0 8px 8px 0 rgba(103, 151, 255, .11), 0 12px 18px 0 rgba(103, 151, 255, .11);
    margin: 0 15px 20px;
}

.orders-table table thead {
    color: #fff;
}

.orders-table table tr>* {
    padding: 0.2em 0.7em;
}

.orders-table table tbody tr {
    border: none;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.orders-table table th {
    padding: 0.5em 0.7em;
}

.orders-table table tr *:first-child {
    text-align: center;
}

.orders-table table tr:first-child td:first-child {
    border-top-left-radius: 20px;
}

.orders-table table tr:first-child td:last-child {
    border-top-right-radius: 20px;
}

.orders-table table tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

.orders-table table tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

.orders-table table td {
    background-color: #fff;
}

.resultSuccessPart .store-table table {
    margin-bottom: 0;
}

.resultSuccessPart .store-table .storeName {
    text-align: center;
    color: #fff;
    background-color: var(--buttonConfirmColor);
    font-family: proximanova_bold, Arial;
    box-shadow: 0 8px 8px 0 rgba(103, 151, 255, .11), 0 12px 18px 0 rgba(103, 151, 255, .11);
    border-radius: 30px;
    border-bottom: 0;
}

.resultSuccessPart .storePopupBtn {
    font-family: proximanova_bold, Arial;
    border: none;
    outline: none;
    border-radius: 30px;
    padding: 12px 24px;
    margin-bottom: 12px;
    background-color: rgb(223, 223, 223);
}

.resultSuccessPart .storePopupBtn:hover {
    background-color: var(--buttonConfirmColor);
}

.orderPart .orders-table .btnOrderRemove {
    margin: auto 5px;
    height: 40px;
    width: 40px;
    background-color: rgba(245, 243, 243, 0);
    outline: none;
    border: none;
    border-radius: 50%;
}

.orderPart .orders-table .btnOrderRemove:hover {
    background-color: rgb(185, 185, 185);
}

.orderPart .orders-table .btnOrderRemove-Icon {
    height: 20px;
    width: 20px;
    color: black;
}

.orderPart .btnGroup {
    max-width: 400px;
    margin-top: 16px;
}


.orderRow {
    vertical-align: middle;
    margin: 15px 10px;
}


.qr-group {
    text-align: center;
    margin: auto 24px auto 10px;
    font-size: 12px;
}

.qr-group button {
    border: none;
    outline: none;
    padding: 0;
    background: none;
    font-family: proximanova_regular, Arial;
}

.qr-group button:hover {
    text-decoration: underline;
}

.col0 {
    width: 0;
}

.col1 {
    width: 80px;
}

.col2 {
    width: 140px;
}

.col3 {
    width: 240px;
}

.col4 {
    width: 320px;
}

.col4 {
    width: 320px;
}

.col5 {
    width: 400px;
}


.mr-1 {
    margin-right: 32px;
}

.popupBg {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.39);
    font-family: proximanova_regular, Arial;
}

.c-poiter {
    cursor: pointer;
}

.qrCodeview {
    height: 60vh;
}

.popupBg-visible {
    z-index: 4;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.39);
    font-family: proximanova_regular, Arial;
}

.popupContent {
    transform: translateY(-50vh);
    margin: auto;
    padding: 10px;
    max-height: 80vh;
    max-width: 90vw;
    overflow: auto;
    border-radius: 15px;
    opacity: 0;
    background-color: rgb(255, 255, 255);
    transition: all 0.5s ease;
}

.popupContent-visible {
    opacity: 1;
    transform: translateY(0);
}

.popupClose {
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: rgb(255, 255, 255);
}

.popupClose:hover {
    background-color: rgb(219, 217, 217);
}


/* * {

    border: 1px solid red;
} */